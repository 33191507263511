import { ArrowLeftIcon, Bars4Icon } from '@heroicons/react/24/outline';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  GetChatRoomsDocument,
  GetMessagesByChatRoomDocument,
  GetChatRoomsQuery,
  GetMessagesByChatRoomQuery,
  GetAgentsDocument,
  GetAgentsQuery,
  MessageRole,
  Message,
  CreateChatRoomDocument,
  CreateChatRoomMutation,
  CreateChatRoomMutationVariables,
  GetUserInfoQuery,
  GetUserInfoQueryVariables
} from '../../gql/graphql';
import ChatContainer from '../../components/chat/ChatContainer';
import { useChat } from '@/context/ChatProvider';
import { useApi } from '@/context/ApiContext';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';
import ChatRoomList from '../../components/chat/ChatRoomList';
import { GET_USER_INFO } from '@/graphql/queries';

const UserChatPage = () => {
  const {
    activeChatRoomId,
    setActiveChatRoomId,
    sendMessage,
    isWaitingForResponse,
    streamingMessage,
    temporalUserMessage,
    messageRefetchNotified,
    setMessageRefetchNotified
  } = useChat();
  const { deleteChatRoom, updateChatRoom } = useApi();

  // Fetch agents
  const { loading: agentsLoading, error: agentsError, data: agentsData } = useQuery<GetAgentsQuery>(GetAgentsDocument);

  const { loading: chatRoomsLoading, error: chatRoomsError, data: chatRoomsData } = useQuery<GetChatRoomsQuery>(GetChatRoomsDocument);

  const {
    loading: messagesLoading,
    error: messagesError,
    data: messagesData,
    refetch: refetchMessages
  } = useQuery<GetMessagesByChatRoomQuery>(GetMessagesByChatRoomDocument, {
    variables: { chatRoomId: activeChatRoomId || '' },
    skip: !activeChatRoomId
  });

  // Map GraphQL messages to IMessage interface
  const { user: keycloakUser } = useKeycloakAuth();

  const { data: userData } = useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GET_USER_INFO, {
    variables: { id: keycloakUser?.id },
    skip: !keycloakUser?.id
  });

  const mappedMessages = messagesData?.findManyMessages
    .map(
      (msg): Message => ({
        id: msg.id,
        text: msg.text || '',
        role: msg.role as MessageRole,
        userId: msg.userId,
        chatRoomId: msg.chatRoomId,
        createdAt: msg.createdAt,
        updatedAt: msg.updatedAt,
        user: msg.userId === keycloakUser?.id ? userData?.findFirstUser : undefined
      })
    )
    .sort((a, b) => a.createdAt.localeCompare(b.createdAt));

  const [isChatSelectionVisible, setIsChatSelectionVisible] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isConversationMenuVisible, setIsConversationMenuVisible] = useState(false);

  const [createChatRoom] = useMutation<CreateChatRoomMutation, CreateChatRoomMutationVariables>(CreateChatRoomDocument);

  const { user } = useKeycloakAuth();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClickConversation = useCallback(
    (id: string) => {
      if (isMobileView) {
        setIsChatSelectionVisible(false);
      }

      // Set active chat room in context
      setActiveChatRoomId(id);
    },
    [isMobileView, setActiveChatRoomId]
  );

  // Set latest chat room as active if none is selected
  useEffect(() => {
    if (!activeChatRoomId && chatRoomsData?.findManyChatRooms?.length) {
      // Get the latest chat room (assuming they're sorted by creation date)
      const latestChatRoom = chatRoomsData.findManyChatRooms[0];
      setActiveChatRoomId(latestChatRoom?.id);
    }
  }, [activeChatRoomId, chatRoomsData, setActiveChatRoomId]);

  const toggleChatSelection = () => {
    setIsChatSelectionVisible((prev) => !prev);
  };

  const handleDeleteMessage = async (messageId: string) => {
    // Delete functionality removed
    console.log('Delete message functionality has been removed');
    return;
  };

  const handleSendMessage = useCallback(
    (messageText: string) => {
      console.log('Attempting to send message:', {
        messageText,
        activeChatRoomId,
        isWaiting: isWaitingForResponse.current
      });

      if (!activeChatRoomId || !messageText.trim() || isWaitingForResponse.current) {
        console.log('Message send blocked:', {
          noChatRoom: !activeChatRoomId,
          emptyMessage: !messageText.trim(),
          isWaiting: isWaitingForResponse.current
        });
        return;
      }

      try {
        console.log('Sending message through Socket.IO');
        // Get the chat history from the messages data
        const chatHistory = messagesData?.findManyMessages || [];
        // Get the current chat room
        const currentChatRoom = chatRoomsData?.findManyChatRooms.find((room) => room.id === activeChatRoomId);
        // Get the current agent using the chat room's agentId
        const currentAgent = agentsData?.findManyAgents?.find((agent) => agent.id === currentChatRoom?.agentId);

        sendMessage(messageText, chatHistory, currentAgent);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    },
    [activeChatRoomId, messagesData, chatRoomsData, agentsData, sendMessage, isWaitingForResponse]
  );

  // Add logging for active chat room changes
  useEffect(() => {
    console.log('Active chat room changed:', {
      activeChatRoomId,
      isWaiting: isWaitingForResponse.current
    });
  }, [activeChatRoomId, isWaitingForResponse]);

  // Update the chat creation handlers to use async/await
  const handleCreateChat = async (agentName: string, agentId: string) => {
    if (!user?.id) {
      console.error('No user ID found');
      return;
    }

    try {
      const response = await createChatRoom({
        variables: {
          name: agentName,
          userId: user.id,
          agentId: agentId
        },
        update: (cache, { data }) => {
          if (!data?.createChatRoom) return;

          // Read the current chat rooms from cache
          const existingChatRooms = cache.readQuery<GetChatRoomsQuery>({
            query: GetChatRoomsDocument
          });

          // Write the new chat room to cache
          cache.writeQuery({
            query: GetChatRoomsDocument,
            data: {
              findManyChatRooms: [data.createChatRoom, ...(existingChatRooms?.findManyChatRooms || [])]
            }
          });

          // Set the new room as active
          setActiveChatRoomId(data.createChatRoom.id);
        }
      });
      return response.data?.createChatRoom;
    } catch (error) {
      console.error('Error creating chat room:', error);
      throw error;
    }
  };

  // Handle delete chat room
  const handleDeleteChatRoom = async (chatRoomId: string) => {
    try {
      await deleteChatRoom(chatRoomId);

      // If the deleted room was active, set another room as active
      if (activeChatRoomId === chatRoomId) {
        const remainingRooms = chatRoomsData?.findManyChatRooms || [];
        const nextRoom = remainingRooms.find((room) => room.id !== chatRoomId);
        setActiveChatRoomId(nextRoom?.id || '');
      }
    } catch (error) {
      console.error('Error deleting chat room:', error);
    }
  };

  const handleUpdateChatRoom = async (chatRoomId: string, newName: string) => {
    try {
      await updateChatRoom(chatRoomId, newName);
    } catch (error) {
      console.error('Error updating chat room:', error);
    }
  };

  // Add effect to handle message refetch
  useEffect(() => {
    const refetchAndReset = async () => {
      if (messageRefetchNotified) {
        await refetchMessages();
        setMessageRefetchNotified(false);
      }
    };
    refetchAndReset();
  }, [messageRefetchNotified, refetchMessages, setMessageRefetchNotified]);

  return (
    <main className='grow'>
      <div className='max-w-9xl mx-auto h-full w-full px-4 py-2 sm:px-6 lg:px-8'>
        {isMobileView ? (
          <div className='h-[100dvh - 100px]'>
            {isChatSelectionVisible ? (
              // Chat Selection View
              <div className='relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden'>
                <div className='border-r p-4 lg:w-1/4'>
                  <div className='mb-4 flex items-center justify-between'>
                    <div className='relative w-full'>
                      <button
                        className='w-full rounded bg-uehPrimary px-4 py-2 font-iciel font-bold text-white'
                        onClick={() => setIsConversationMenuVisible(!isConversationMenuVisible)}>
                        Tạo trò chuyện mới
                      </button>
                      {isConversationMenuVisible && (
                        <>
                          <div className='fixed inset-0' onClick={() => setIsConversationMenuVisible(false)} />
                          <div className='absolute left-0 z-10 mt-2 w-full rounded border border-gray-300 bg-white shadow-lg'>
                            {agentsLoading ? (
                              <div className='px-4 py-2 text-sm text-gray-700'>Loading agents...</div>
                            ) : agentsError ? (
                              <div className='px-4 py-2 text-sm text-red-600'>Error loading agents</div>
                            ) : agentsData?.findManyAgents && agentsData.findManyAgents.length > 0 ? (
                              agentsData.findManyAgents.map((agent) => (
                                <button
                                  key={agent.id}
                                  className='block w-full px-4 py-2 text-left text-sm font-bold text-gray-700 hover:bg-gray-100 hover:text-uehPrimary'
                                  onClick={async () => {
                                    await handleCreateChat(agent.name, agent.id);
                                    setIsConversationMenuVisible(false);
                                  }}>
                                  {agent.name}
                                </button>
                              ))
                            ) : (
                              <div className='px-4 py-2 text-sm text-gray-700'>No agents available</div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <button className='w-10 rounded px-4 py-2 font-iciel font-bold text-uehPrimary' onClick={toggleChatSelection}>
                      <ArrowLeftIcon className='h-5 w-5' />
                    </button>
                  </div>
                  <hr className='my-4' />
                  <ChatRoomList
                    loading={chatRoomsLoading}
                    chatRoomsData={chatRoomsData}
                    activeChatRoomId={activeChatRoomId}
                    handleClickConversation={handleClickConversation}
                    setIsChatSelectionVisible={setIsChatSelectionVisible}
                  />
                </div>
              </div>
            ) : (
              // Chat Page View
              <div className='relative flex flex-1 flex-col overflow-hidden'>
                <button onClick={toggleChatSelection}>Mở danh sách chat</button>
                <ChatContainer
                  messages={mappedMessages ?? []}
                  loading={messagesLoading}
                  onSendMessage={handleSendMessage}
                  isLoading={isWaitingForResponse.current}
                  isWaitingForResponse={isWaitingForResponse.current}
                  streamingMessage={streamingMessage}
                  temporalUserMessage={temporalUserMessage}
                />
              </div>
            )}
          </div>
        ) : (
          // Desktop View
          <>
            <div className='relative flex flex-1 overflow-y-auto overflow-x-hidden'>
              <div className='border-r p-4 lg:w-1/5'>
                <div className='mb-4 flex items-center justify-between'>
                  <div className='flex items-center space-x-2'>
                    <span className='font-bold'>Lịch sử chat</span>
                    <span className='rounded-full bg-gray-200 px-2 py-1 text-xs text-gray-700'>{chatRoomsData?.findManyChatRooms.length || 0}</span>
                  </div>
                  <div className='relative'>
                    <PlusCircleIcon
                      className='h-6 w-6 cursor-pointer rounded-full text-uehPrimary transition-transform duration-300 hover:scale-110'
                      onClick={() => setIsConversationMenuVisible(!isConversationMenuVisible)}
                    />
                    {isConversationMenuVisible && (
                      <>
                        <div className='fixed inset-0' onClick={() => setIsConversationMenuVisible(false)} />
                        <div className='absolute right-0 z-10 mt-2 w-48 rounded border border-gray-300 bg-white shadow-lg'>
                          {agentsLoading ? (
                            <div className='px-4 py-2 text-sm text-gray-700'>Loading agents...</div>
                          ) : agentsError ? (
                            <div className='px-4 py-2 text-sm text-red-600'>Error loading agents</div>
                          ) : agentsData?.findManyAgents && agentsData.findManyAgents.length > 0 ? (
                            agentsData.findManyAgents.map((agent) => (
                              <button
                                key={agent.id}
                                className='block w-full px-4 py-2 text-left text-sm font-bold text-gray-700 hover:bg-gray-100 hover:text-uehPrimary'
                                onClick={async () => {
                                  await handleCreateChat(agent.name, agent.id);
                                  setIsConversationMenuVisible(false);
                                }}>
                                {agent.name}
                              </button>
                            ))
                          ) : (
                            <div className='px-4 py-2 text-sm text-gray-700'>No agents available</div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <hr className='mb-4' />
                <ChatRoomList
                  loading={chatRoomsLoading}
                  chatRoomsData={chatRoomsData}
                  activeChatRoomId={activeChatRoomId}
                  handleClickConversation={handleClickConversation}
                  setIsChatSelectionVisible={setIsChatSelectionVisible}
                />
              </div>
              <div className='flex-1'>
                <ChatContainer
                  messages={mappedMessages ?? []}
                  loading={messagesLoading}
                  onSendMessage={handleSendMessage}
                  isLoading={isWaitingForResponse.current}
                  isWaitingForResponse={isWaitingForResponse.current}
                  streamingMessage={streamingMessage}
                  temporalUserMessage={temporalUserMessage}
                  messageRefetchNotified={messageRefetchNotified}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default UserChatPage;
