import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';

interface ToolbarProps {
  userName: string;
  firstLetter?: string;
}

const Toolbar = ({ userName, firstLetter }: ToolbarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useKeycloakAuth();

  const handleProfileClick = () => {
    navigate('/settings/profile');
    setIsOpen(false);
  };

  const handleLogoutClick = async () => {
    try {
      await logout();
      setIsOpen(false);
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  return (
    <div className='relative'>
      <button 
        className='flex items-center space-x-2 p-2 rounded-full hover:bg-gray-100 focus:outline-none' 
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-uehPrimary text-white font-medium'>
          {firstLetter || userName.charAt(0)}
        </div>
        <span className='hidden md:block font-medium font-myriad'>{userName}</span>
        <ChevronDownIcon className='hidden md:block w-5 h-5 text-gray-500' />
      </button>
      {isOpen && (
        <div className='absolute z-30 right-0 mt-2 w-28 bg-white border rounded shadow-lg font-myriad'>
          <button 
            onClick={handleProfileClick} 
            className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
          >
            Cài đặt
          </button>
          <button 
            onClick={handleLogoutClick} 
            className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
          >
            Đăng xuất
          </button>
        </div>
      )}
    </div>
  );
};

export default Toolbar;
