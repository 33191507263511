import React from 'react';
import { Document, Folder, DocumentStatus } from '@/gql/graphql';
import { DocumentIcon, FolderIcon, TrashIcon, PlusCircleIcon, PencilIcon } from '@heroicons/react/24/outline';
import { formatDate, formatFileSize } from '@/utils/file_util';
import StatusIndicator from './StatusIndicator';
import { getExtension } from '@/utils/document_util';

interface FolderTreeViewProps {
  folderTree: Folder[];
  openFolders: Record<string, boolean>;
  selectedDocuments: string[];
  selectedFolders: string[];
  fileStatuses: Record<string, { 
    isUploading: boolean;
    document: Document;
    folderId?: string;
    progress: number;
  }>;
  onToggleFolder: (folderId: string) => void;
  onFolderSelection: (id: string, checked: boolean) => void;
  onFolderDelete: (folderIds: string[]) => void;
  onUploadToFolder: (folderId: string) => void;
  setCurrentRecord: (record: Document) => void;
  showRenameModal: () => void;
  showMoveFileModal: () => void;
  handleRemoveFile: (recordIds: string[]) => void;
}

const FolderTreeView: React.FC<FolderTreeViewProps> = ({
  folderTree,
  openFolders,
  selectedDocuments,
  selectedFolders,
  fileStatuses,
  onToggleFolder,
  onFolderSelection,
  onFolderDelete,
  onUploadToFolder,
  setCurrentRecord,
  showRenameModal,
  showMoveFileModal,
  handleRemoveFile
}) => {
  const renderFolderContent = (folder: Folder, depth = 0) => {
    // Get uploading files that belong to this folder
    const uploadingFiles = Object.entries(fileStatuses)
      .filter(([_, status]) => status.isUploading && status.folderId === folder.id)
      .map(([fileId, status]) => ({
        ...status.document,
        id: fileId
      }));

    return (
      <React.Fragment key={folder.id}>
        {/* Folder Row */}
        <tr className="hover:bg-gray-50">
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div style={{ marginLeft: folder.parentId ? `${(depth + 1) * 1.5}rem` : `${depth * 1.5}rem` }} className="flex items-center">
                {folder.parentId === null && (
                  <input
                    type="checkbox"
                    checked={selectedFolders.includes(folder.id)}
                    onChange={(e) => onFolderSelection(folder.id, e.target.checked)}
                    className="h-4 w-4 text-uehPrimary focus:ring-uehSecondary border-gray-300 rounded mr-2"
                  />
                )}
                <button onClick={() => onToggleFolder(folder.id)} className="p-1 hover:bg-gray-200 rounded-full mr-2">
                  <svg
                    className={`w-4 h-4 transform transition-transform ${openFolders[folder.id] ? 'rotate-90' : ''}`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <FolderIcon className="h-5 w-5 text-uehPrimary mr-2" />
                <div>
                  <div className="text-sm font-medium text-gray-900">{folder.name}</div>
                  <div className="text-sm text-gray-500">({folder.documents?.length || 0} files)</div>
                </div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(folder.createdAt)}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">-</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">-</td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            <div className="flex items-center space-x-3 justify-end">
              <button
                onClick={() => onUploadToFolder(folder.id)}
                className="text-uehPrimary hover:text-uehSecondary"
              >
                <PlusCircleIcon className="w-5 h-5" />
              </button>
              <button 
                onClick={() => onFolderDelete([folder.id])} 
                className="text-red-600 hover:text-red-900"
              >
                <TrashIcon className="w-5 h-5" />
              </button>
            </div>
          </td>
        </tr>

        {/* Document Rows */}
        {openFolders[folder.id] && (
          <>
            {(() => {
              // Get all documents from the folder
              const folderDocs = folder.documents || [];
              
              // Get uploading files for this folder
              const uploadingFiles = Object.entries(fileStatuses)
                .filter(([_, status]) => status.isUploading && status.folderId === folder.id)
                .map(([fileId, status]) => ({
                  ...status.document,
                  id: fileId
                }));

              // Render both uploading files and folder documents
              return [
                // First render uploading files
                ...uploadingFiles.map((doc) => {
                  const progress = fileStatuses[doc.id].progress;

                  return (
                    <tr key={doc.id} className="hover:bg-gray-50 bg-gray-50 opacity-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div style={{ marginLeft: `${(depth + 2) * 1.5}rem` }} className="flex items-center">
                            <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-2" aria-hidden="true" />
                            <div>
                              <div className="text-sm font-medium text-gray-900">{doc.title}</div>
                              <div className="text-sm text-gray-500">{getExtension(doc.title)}</div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">-</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                          <div className="bg-uehPrimary h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{progress}%</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button onClick={() => handleRemoveFile([doc.id])} className="text-red-600 hover:text-red-900">
                          <TrashIcon className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  );
                }),

                // Then render folder documents
                ...folderDocs.map((doc) => (
                  <tr key={doc.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div style={{ marginLeft: `${(depth + 2) * 1.5}rem` }} className="flex items-center">
                          <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-2" aria-hidden="true" />
                          <div>
                            <div className="text-sm font-medium text-gray-900">{doc.title}</div>
                            <div className="text-sm text-gray-500">{getExtension(doc.title)}</div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(doc.createdAt)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatFileSize(doc.fileSize)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <StatusIndicator status={doc.status} />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex items-center space-x-3 justify-end">
                        <button
                          onClick={() => {
                            setCurrentRecord(doc);
                            showRenameModal();
                          }}
                          className="text-uehPrimary hover:text-uehSecondary"
                        >
                          <PencilIcon className="w-5 h-5" />
                        </button>
                        <button onClick={() => handleRemoveFile([doc.id])} className="text-red-600 hover:text-red-900">
                          <TrashIcon className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ];
            })()}
            
            {/* Render subfolders recursively */}
            {folder.subFolders?.map((subfolder) => renderFolderContent(subfolder, depth + 1))}
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Size
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {folderTree.map((folder) => renderFolderContent(folder))}
        </tbody>
      </table>
    </div>
  );
};

export default FolderTreeView;
