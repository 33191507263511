import { LockClosedIcon, UserIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export const UserSettingBaseKey = 'settings';

export const UserSettingRoutes = [
  { key: 'Profile', icon: UserIcon },
  // { key: 'Password', icon: LockClosedIcon }
  // { key: 'System', icon: UsersIcon },
  // { key: 'Team', icon: UserFolderIcon },
  // { key: 'Logout', icon: ArrowRightStartOnRectangleIcon }
  // { key: 'Model', icon: CubeTransparentIcon },
];

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>('Profile');

  useEffect(() => {
    const currentPath = location.pathname.split('/').pop();
    if (currentPath && UserSettingRoutes.map((route) => route.key).includes(currentPath)) {
      setActiveItem(currentPath);
    }
  }, [location.pathname]);

  const handleMenuClick = (key: string) => {
    if (key === UserSettingRoutes.find((route) => route.key === 'Logout')?.key) {
      // TODO: Implement logout logic here
    } else {
      navigate(`/${UserSettingBaseKey}/${key}`);
    }
  };

  return (
    <section className='w-80 mt-4 h-screen border-r border-gray-200'>
      <ul className='space-y-2 font-iciel font-medium'>
        {UserSettingRoutes.map((route) => (
          <li
            key={route.key}
            className={`flex items-center p-2 hover:bg-gray-100 hover:text-uehPrimary cursor-pointer transition-colors duration-400 ${
              activeItem === route.key ? 'bg-uehPrimary bg-opacity-80 text-white hover:none' : ''
            }`}
            onClick={() => handleMenuClick(route.key)}>
            <div className='flex items-center px-5 items-center justify-center'>
              <route.icon className='w-5 h-5 mr-2' />
              <span className=''>{route.key}</span>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default SideBar;
