import { SupportedPreviewDocumentTypes } from '../constants/common';

export const getExtension = (name: string) => {
  const extension = name?.split('.')?.[name.split('.').length - 1]?.toLowerCase() ?? '';
  return extension === '' ? 'folder' : extension;
};

export const isPdf = (name: string) => {
  return getExtension(name) === 'pdf';
};

export const isSupportedPreviewDocumentType = (fileExtension: string) => {
  return SupportedPreviewDocumentTypes.includes(fileExtension);
};
