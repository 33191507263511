import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useQuery, useMutation } from '@apollo/client';
import { GetUserInfoQuery, GetUserInfoQueryVariables, UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables } from '@/gql/graphql';
import { GET_USER_INFO, UPDATE_USER_SETTINGS } from '@/graphql/queries';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';
import { useState } from 'react';
import { ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

type FieldType = {
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

const UserSettingProfile = () => {
  const navigate = useNavigate();
  const { user } = useKeycloakAuth();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { data, loading } = useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GET_USER_INFO, {
    variables: { id: user?.id },
    skip: !user?.id
  });
  const [updateSettings, { loading: submitLoading }] = useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
    UPDATE_USER_SETTINGS
  );
  const userInfo = data?.findFirstUser;

  const saveSetting = async (values: FieldType) => {
    try {
      await updateSettings({
        variables: {
          id: user?.id || '',
          nickname: values.username,
          firstName: values.firstName,
          lastName: values.lastName
        },
        refetchQueries: [{ query: GET_USER_INFO, variables: { id: user?.id || '' } }]
      });
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000); // Reset after 3 seconds
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: userInfo?.username || '',
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.lastName || '',
      email: userInfo?.email || ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Username is required'),
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required')
    }),
    onSubmit: async (values: FieldType) => {
      saveSetting(values);
    }
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className='p-4'>
      <form onSubmit={formik.handleSubmit} className='space-y-6 font-myriad'>
        <div className='bg-white rounded-lg shadow p-6'>
          <div className='space-y-4'>
            {/* Username Field */}
            <div className='grid grid-cols-1 md:grid-cols-4 gap-4 items-center'>
              <label htmlFor='username' className='block text-sm font-medium font-iciel mb-2 col-span-1'>
                Username
              </label>
              <input
                type='text'
                id='username'
                name='username'
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='w-full px-4 py-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-uehPrimary focus:border-transparent col-span-3'
              />
              <div className='col-span-1'></div>
              {formik.touched.username && formik.errors.username && (
                <p className='text-red-500 text-sm col-span-3 font-myriad'>{formik.errors.username}</p>
              )}
            </div>

            {/* First Name Field */}
            <div className='grid grid-cols-1 md:grid-cols-4 gap-4 items-center'>
              <label htmlFor='firstName' className='block text-sm font-medium font-iciel mb-2 col-span-1'>
                First Name
              </label>
              <input
                type='text'
                id='firstName'
                name='firstName'
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='w-full px-4 py-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-uehPrimary focus:border-transparent col-span-3'
              />
              <div className='col-span-1'></div>
              {formik.touched.firstName && formik.errors.firstName && (
                <p className='text-red-500 text-sm col-span-3 font-myriad'>{formik.errors.firstName}</p>
              )}
            </div>

            {/* Last Name Field */}
            <div className='grid grid-cols-1 md:grid-cols-4 gap-4 items-center'>
              <label htmlFor='lastName' className='block text-sm font-medium font-iciel mb-2 col-span-1'>
                Last Name
              </label>
              <input
                type='text'
                id='lastName'
                name='lastName'
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='w-full px-4 py-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-uehPrimary focus:border-transparent col-span-3'
              />
              <div className='col-span-1'></div>
              {formik.touched.lastName && formik.errors.lastName && (
                <p className='text-red-500 text-sm col-span-3 font-myriad'>{formik.errors.lastName}</p>
              )}
            </div>

            {/* Email Field */}
            <div className='grid grid-cols-1 md:grid-cols-4 gap-4 items-center'>
              <label htmlFor='email' className='block text-sm font-medium font-iciel mb-2 col-span-1'>
                Email
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={formik.values.email}
                disabled
                className='w-full px-4 py-2 border border-gray-200 rounded-md bg-gray-50 col-span-3'
              />
              <div className='col-span-1'></div>
              <p className='mt-1 text-sm text-gray-500 col-span-3'>Your email cannot be changed.</p>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className='flex justify-end space-x-4'>
          <button
            type='button'
            onClick={() => navigate(-1)}
            className='px-6 py-2 border border-gray-200 rounded-md hover:bg-gray-50 font-iciel font-medium'>
            Cancel
          </button>
          <button
            type='submit'
            disabled={submitLoading}
            className={`
              px-6 py-2 rounded-md font-iciel font-semibold
              flex items-center justify-center min-w-[140px]
              transition-all duration-200
              ${
                saveSuccess
                  ? 'bg-green-500 hover:bg-green-600'
                  : submitLoading
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-uehPrimary hover:bg-uehSecondary'
              }
              text-white disabled:opacity-50
            `}>
            {submitLoading ? (
              <div className='flex items-center'>
                <ArrowPathIcon className='animate-spin -ml-1 mr-2 h-4 w-4 text-white' />
                Saving...
              </div>
            ) : saveSuccess ? (
              <div className='flex items-center'>
                <CheckCircleIcon className='w-5 h-5 mr-2' />
                Saved!
              </div>
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </form>
    </section>
  );
};

export default UserSettingProfile;
