import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import UploadModal from '../../components/file/UploadModal';
import FolderCreateModal from '../../components/file/FolderCreateModal';
import CreateAgentModal, { AgentConfig } from '../../components/file/CreateAgentModal';
import FolderTreeView from '../../components/file/FolderTreeView';
import {
  Document,
  DocumentStatus,
  Folder,
  GetDocumentsQuery,
  GetFoldersDocument,
  GetFoldersQuery
} from '@/gql/graphql';
import { useQuery } from '@apollo/client';
import { GET_DOCUMENTS } from '../../graphql/queries';

import { FolderIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';
import { getExtension } from '@/utils/document_util';
import { generateUniqueId, printFolderTree } from '@/utils/file_util';
import { FileNode, FolderNode, useChat } from '@/context/ChatProvider';
import { useApi } from '@/context/ApiContext';

const FilePage = () => {
  const { loading: foldersLoading, error: foldersError, data: foldersData, refetch: refetchFolders } = useQuery<GetFoldersQuery>(GetFoldersDocument);
  const { user } = useKeycloakAuth();

  // Add documents query
  const { loading: documentsLoading, data: documentsData, refetch: refetchDocuments } = useQuery<GetDocumentsQuery>(GET_DOCUMENTS);

  const { uploadFolderTree, deleteDocument, fileStatuses, setFileStatus, clearFileStatuses, setFolderRefetch, setDocumentRefetch } =
    useChat();

  const { deleteFolder, createFolder } = useApi();

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);
  const [folderDocuments, setFolderDocuments] = useState<string[]>([]);
  const [fileRenameVisible, setFileRenameVisible] = useState(false);
  const [folderCreateModalVisible, setFolderCreateModalVisible] = useState(false);
  const [fileUploadVisible, setFileUploadVisible] = useState(false);
  const [moveFileVisible, setMoveFileVisible] = useState(false);
  const [uploadTargetFolderId, setUploadTargetFolderId] = useState<string>('');
  const [isMobileView, setIsMobileView] = useState(false);
  const [showCreateAgentModal, setShowCreateAgentModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [openFolders, setOpenFolders] = useState<Record<string, boolean>>({});

  // Folder tree
  const folderTree = useMemo(() => {
    if (!foldersData?.findManyFolders || !documentsData?.findManyDocuments) {
      return [];
    }

    const folders = foldersData.findManyFolders;
    const documents = documentsData.findManyDocuments;

    // Create a map of folders by their IDs
    const folderMap = new Map<string, Folder>();

    // Initialize all folders
    folders.forEach((folder) => {
      folderMap.set(folder.id, {
        ...folder,
        subFolders: [],
        documents: [],
        agentFolders: []
      });
    });

    // Add documents to their respective folders first
    documents.forEach((doc: Document) => {
      const folder = doc.folderId ? folderMap.get(doc.folderId) : null;
      if (folder) {
        folder.documents?.push(doc);
      }
    });

    // Build folder hierarchy after documents are added
    folders.forEach((folder) => {
      if (folder.parentId) {
        const parentFolder = folderMap.get(folder.parentId);
        if (parentFolder) {
          // Add this folder as a subfolder to its parent
          parentFolder.subFolders?.push(folderMap.get(folder.id)!);
        }
      }
    });

    // Get only root level folders (those without parentId)
    // This ensures we don't duplicate subfolders in the tree
    const rootFolders = folders
      .filter((folder) => !folder.parentId)
      .map((folder) => folderMap.get(folder.id)!)
      .filter(Boolean);

    console.log('Folder tree:', rootFolders);
    return rootFolders;
  }, [foldersData?.findManyFolders, documentsData?.findManyDocuments]);

  // Initialize openFolders state when folders change
  useEffect(() => {
    if (foldersData?.findManyFolders) {
      const initialOpenState = foldersData.findManyFolders.reduce(
        (acc, folder) => {
          acc[folder.id] = true; // Set all folders to be open by default
          return acc;
        },
        {} as Record<string, boolean>
      );
      setOpenFolders(initialOpenState);
    }
  }, [foldersData?.findManyFolders]);

  // Debug print folder tree whenever it changes
  useEffect(() => {
    if (folderTree.length > 0) {
      console.log('Current Folder Tree Structure:');

      // Print each root folder from the processed folderTree
      folderTree.forEach((folder, index) => {
        console.log(printFolderTree(folder, 0, index === folderTree.length - 1));
      });
    }
  }, [folderTree]);

  useEffect(() => {
    setFolderRefetch(async () => await refetchFolders());
    setDocumentRefetch(async () => await refetchDocuments());
  }, [refetchDocuments, refetchFolders, setFolderRefetch, setDocumentRefetch]);

  const toggleFolder = (folderId: string) => {
    setOpenFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Logging documents
  useEffect(() => {
    const documents = documentsData?.findManyDocuments;
    if (documents && documents.length > 0) {
      console.log('Fetched documents:', documents);
    }
  }, [documentsData]);

  // Refetch documents and clear file statuses when documents are loaded
  useEffect(() => {
    if (documentsData?.findManyDocuments) {
      clearFileStatuses();
    }
  }, [documentsData?.findManyDocuments, clearFileStatuses]);

  const handleFileRename = () => {
    // Implement file rename logic
    console.info('Renaming file');
  };

  const handleFileUpload = async (files: File[], folderId: string) => {
    const selectedFolder = foldersData?.findManyFolders.find((g) => g.id === folderId);
    if (!selectedFolder) return;

    // Close modal immediately
    setFileUploadVisible(false);

    // Clear any existing file statuses before starting new upload
    clearFileStatuses();

    try {
      // Create a map to store file paths and their content
      const fileMap = new Map<string, { file: File; content: string }>();

      // Process all files and build the file map
      for (const file of files) {
        try {
          // Validate file before creating any UI state
          if (!validateFile(file)) {
            throw new Error('Invalid file type or size');
          }

          const fileContent = await readFileAsBase64(file);
          const relativePath = file.webkitRelativePath || file.name;
          fileMap.set(relativePath, { file, content: fileContent });
        } catch (error) {
          const errorFileId = generateUniqueId();
          console.error(`Error processing file ${file.name}:`, error);
          setFileStatus(errorFileId, {
            isUploading: false,
            document: {
              id: errorFileId,
              title: file.name,
              fileSize: file.size,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              status: DocumentStatus.Error,
              userId: user?.id || '',
              extension: file.name.split('.').pop() || '',
              folderId: folderId
            },
            error: error instanceof Error ? error.message : 'Unknown error occurred',
            folderId: folderId,
            progress: 0
          });
        }
      }

      // Build folder structure using existing folder as root
      const rootFolder: FolderNode = {
        name: selectedFolder.name,
        path: selectedFolder.name,
        type: 'folder',
        children: []
      };

      // Process files and create folder structure
      for (const [path, { file, content }] of fileMap) {
        const pathParts = path.split('/');
        const fileName = pathParts.pop()!;
        let currentNode = rootFolder;

        // Create folder structure
        for (const folderName of pathParts) {
          if (folderName === '') continue;
          if (folderName === selectedFolder.name) continue; // Skip if it's the root folder name

          let folderNode = currentNode.children.find((child) => child.type === 'folder' && child.name === folderName) as FolderNode | undefined;

          if (!folderNode) {
            folderNode = {
              name: folderName,
              path: `${currentNode.path}/${folderName}`,
              type: 'folder',
              children: []
            };
            currentNode.children.push(folderNode);
          }

          currentNode = folderNode;
        }

        // Add file node
        const fileNode: FileNode = {
          name: fileName,
          path: `${currentNode.path}/${fileName}`,
          type: 'file',
          content: content,
          size: file.size
        };
        currentNode.children.push(fileNode);
      }

      // Upload the entire folder tree structure
      await uploadFolderTree(rootFolder);

      // Refetch both folders and documents after upload is complete
      await Promise.all([refetchFolders(), refetchDocuments()]);
    } catch (error) {
      console.error('Error uploading folder tree:', error);
    }
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        const base64Content = result.split(',')[1];
        resolve(base64Content);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const [currentRecord, setCurrentRecord] = useState<Document | null>(null);

  const handleRemoveFile = useCallback(
    async (recordIds: string[]) => {
      try {
        for (const recordId of recordIds) {
          await deleteDocument(recordId);
        }
        refetchDocuments();
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    },
    [deleteDocument, refetchDocuments]
  );

  const handleDeleteFile = async (documentId: string) => {
    try {
      // Show confirmation dialog
      if (!window.confirm('Are you sure you want to delete this document?')) {
        return;
      }

      const success = await deleteDocument(documentId);
      if (success) {
        // Remove from file statuses if it exists
        setFileStatus(documentId, {
          ...fileStatuses[documentId],
          isUploading: false
        });

        // Refetch documents to update the list
        await refetchDocuments();
        // message.success('Document deleted successfully');
      } else {
        // message.error('Failed to delete document');
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      // message.error('Failed to delete document');
    }
  };

  const handleFolderDelete = async (folderIds: string[]) => {
    // Show confirmation dialog
    if (!window.confirm('Are you sure you want to delete the selected folders?')) {
      return;
    }

    try {
      for (const folderId of folderIds) {
        const result = await deleteFolder(folderId);
        if (result.status !== 200) {
          throw new Error(result.status_msg || 'Failed to delete folder');
        }
      }
      
      // Clear selections after successful deletion
      setSelectedFolders([]);
      setFolderDocuments([]);
      await refetchFolders();
    } catch (error) {
      console.error('Error deleting folders:', error);
      alert('Failed to delete one or more folders');
    }
  };

  const checkboxRef = useRef<HTMLInputElement>(null);

  const renderActionButtons = () => {
    const hasRootFolders = folderTree.length > 0;
    const hasSelectedRootFolders = selectedFolders.length > 0 && selectedFolders.every(folderId => 
      folderTree.some(folder => folder.id === folderId)
    );

    return (
      <div className='flex justify-between items-center mb-4'>
        <div className='flex space-x-2'>
          <button
            onClick={() => setFolderCreateModalVisible(true)}
            className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'>
            <FolderIcon className='h-5 w-5 mr-2' />
            New Folder
          </button>
        </div>
        <button
          onClick={() => setShowCreateAgentModal(true)}
          disabled={!hasSelectedRootFolders}
          className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            hasSelectedRootFolders ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-400 cursor-not-allowed'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
          title={!hasSelectedRootFolders ? 'Please select one or more root folders' : 'Create agent'}>
          <PlusCircleIcon className='h-5 w-5 mr-2' />
          Create Agent
        </button>
      </div>
    );
  };

  const handleCreateFolder = async (name: string, description: string) => {
    try {
      const selectedFolderId = selectedFolders[0];
      let response;
      
      if (selectedFolderId) {
        // Create subfolder
        response = await createFolder(name, description, selectedFolderId);
      } else {
        // Create root folder
        response = await createFolder(name, description);
      }

      if (response.status === 200) {
        refetchFolders();
        setFolderCreateModalVisible(false);
      } else {
        console.error('Failed to create folder:', response.status_msg);
      }
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  const handleFileSelection = (fileId: string, checked: boolean) => {
    if (checked) {
      // When selecting documents, clear any folder selections
      if (selectedFolders.length > 0) {
        setSelectedFolders([]);
        setFolderDocuments([]);
      }

      // Add document to selections
      setSelectedDocuments((prev) => [...new Set([...prev, fileId])]);
    } else {
      // Remove document from selections
      setSelectedDocuments((prev) => prev.filter((id) => id !== fileId));
    }
  };

  const handleFolderSelect = (folderId: string, selected: boolean) => {
    if (selected) {
      setSelectedFolders([...selectedFolders, folderId]);
      // Clear direct document selections
      setSelectedDocuments([]);
    } else {
      setSelectedFolders(selectedFolders.filter((id) => id !== folderId));
      if (selectedFolders.length === 1) {
        setFolderDocuments([]);
      }
    }
  };

  const handleDocumentSelect = (documentId: string, selected: boolean) => {
    if (selected) {
      setSelectedDocuments([...selectedDocuments, documentId]);
      // Clear folder selections and their documents
      setSelectedFolders([]);
      setFolderDocuments([]);
    } else {
      setSelectedDocuments(selectedDocuments.filter((id) => id !== documentId));
    }
  };

  const isFileInSelectedFolder = (fileId: string): boolean => {
    return folderDocuments.includes(fileId);
  };

  const handleUploadComplete = useCallback(
    (fileId: string) => {
      setFileStatus(fileId, {
        ...fileStatuses[fileId],
        isUploading: false
      });
      refetchDocuments();
    },
    [fileStatuses, setFileStatus, refetchDocuments]
  );

  const allDocuments = useMemo(() => {
    const queryDocs = documentsData?.findManyDocuments || [];

    // Get temporary uploading files
    const temporalDocs = Object.entries(fileStatuses)
      .filter(([_, status]) => status.isUploading)
      .filter(([_, status]) => !queryDocs.some((doc) => doc.id === status.document.id))
      .map(([_, status]) => status.document);

    return [...queryDocs, ...temporalDocs];
  }, [documentsData?.findManyDocuments, fileStatuses]);

  const handleCreateAgent = async (config: AgentConfig) => {
    try {
      // Create agent with selected folders as root folders
      console.log('Creating agent with root folders:', selectedFolders);
      console.log('Agent config:', config);

      // Reset selections after successful creation
      setSelectedFolders([]);
      setShowCreateAgentModal(false);
    } catch (error) {
      console.error('Error creating agent:', error);
    }
  };

  const handleConnectToKnowledge = async (config: AgentConfig) => {
    try {
      // Create agent with selected folders as root folders
      console.log('Creating agent with root folders:', selectedFolders);
      console.log('Agent config:', config);

      // Reset selections after successful creation
      setSelectedFolders([]);
      setShowCreateAgentModal(false);
    } catch (error) {
      console.error('Error creating agent:', error);
    }
  };

  const validateFile = (file: File): boolean => {
    const maxSize = 50 * 1024 * 1024; // 50MB
    const allowedTypes = ['.pdf', '.md'];

    if (file.size > maxSize) {
      throw new Error('File size exceeds 50MB limit');
    }

    const extension = getExtension(file.name).toLowerCase();
    if (!allowedTypes.includes(`.${extension}`)) {
      throw new Error(`File type .${extension} is not supported`);
    }

    return true;
  };

  // Add effect to refetch documents when all files are processed
  useEffect(() => {
    const hasUploadingFiles = Object.values(fileStatuses).some((status) => status.isUploading);
    if (!hasUploadingFiles) {
      refetchDocuments();
    }
  }, [fileStatuses, refetchDocuments]);

  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='flex-1 overflow-y-auto p-4'>
        {renderActionButtons()}

        {foldersLoading || documentsLoading ? (
          <div>Loading...</div>
        ) : foldersError ? (
          <div>Error loading folders</div>
        ) : (
          <FolderTreeView
            folderTree={folderTree}
            openFolders={openFolders}
            selectedDocuments={selectedDocuments}
            selectedFolders={selectedFolders}
            fileStatuses={Object.fromEntries(
              Object.entries(fileStatuses).map(([recordId, status]) => [
                recordId,
                { ...status, document: status.document }
              ])
            )}
            onToggleFolder={toggleFolder}
            onFolderSelection={handleFolderSelect}
            onFolderDelete={handleFolderDelete}
            onUploadToFolder={(folderId) => {
              setUploadTargetFolderId(folderId);
              setFileUploadVisible(true);
            }}
            setCurrentRecord={setCurrentRecord}
            showRenameModal={() => setFileRenameVisible(true)}
            showMoveFileModal={() => setMoveFileVisible(true)}
            handleRemoveFile={handleRemoveFile}
          />
        )}
      </div>

      <UploadModal
        visible={fileUploadVisible}
        hideModal={() => setFileUploadVisible(false)}
        loading={isUploading}
        folderId={uploadTargetFolderId}
        onOk={handleFileUpload}
      />

      <FolderCreateModal
        visible={folderCreateModalVisible}
        hideModal={() => setFolderCreateModalVisible(false)}
        loading={false}
        onOk={handleCreateFolder}
      />

      <CreateAgentModal
        isOpen={showCreateAgentModal}
        onClose={() => setShowCreateAgentModal(false)}
        onSubmit={handleCreateAgent}
        selectedFolders={selectedFolders}
      />
    </div>
  );
};

export default FilePage;
