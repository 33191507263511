import React, { useState, useEffect } from 'react';
import { XMarkIcon, FolderIcon, ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useApi } from '@/context/ApiContext';
import { useQuery } from '@apollo/client';
import { GET_LANGUAGES } from '@/graphql/queries';
import { apiClient } from '@/apollo/client';
import { GetLanguagesQuery, GetLanguagesQueryVariables } from '@/gql/graphql';

interface CreateAgentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (config: AgentConfig) => void;
  selectedFolders: string[];
}

export interface AgentConfig {
  name: string;
  welcomeMessage: string;
  systemPrompt: string;
  errorMessage: string;
  language: string;
}

const defaultConfig: AgentConfig = {
  name: '',
  welcomeMessage: 'Hello! How can I assist you today?',
  systemPrompt:
    'You are a helpful assistant with access to specific knowledge from the provided documents. Answer questions accurately based on this information.',
  errorMessage: 'I apologize, but I encountered an error. Please try again or contact support if the issue persists.',
  language: ''
};

const CreateAgentModal: React.FC<CreateAgentModalProps> = ({ isOpen, onClose, onSubmit, selectedFolders }) => {
  const [config, setConfig] = useState<AgentConfig>(defaultConfig);
  const [error, setError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { createAgent } = useApi();

  const { data: languagesData, loading: languagesLoading } = useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GET_LANGUAGES, {
    client: apiClient
  });

  useEffect(() => {
    // Set default language when languages data is loaded
    if (languagesData?.findManyLanguages && languagesData.findManyLanguages.length > 0 && !config.language) {
      updateConfig('language', languagesData.findManyLanguages[0].id);
    }
  }, [languagesData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);
  
    try {
      const response = await createAgent(
        config.name,
        config.welcomeMessage,
        config.systemPrompt,
        config.errorMessage,
        config.language,
        selectedFolders
      );
  
      if (response) {
        setSaveSuccess(true);
        setTimeout(() => {
          setSaveSuccess(false);
          onSubmit(config);
          onClose();
        }, 1000); // Show success state for 1 second before closing
      }
    } catch (error: any) {
      console.error('Error creating agent:', error);
      if (error.message?.includes('Unique constraint failed')) {
        setError('An agent with this name already exists. Please choose a different name.');
      } else {
        setError('Failed to create agent. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateConfig = (field: keyof AgentConfig, value: any) => {
    setConfig((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const renderSelectionSummary = () => {
    const baseClasses = 'mb-4 p-4 rounded-lg transition-all duration-300 ease-in-out';
    const itemClasses = 'flex items-center space-x-2 px-3 py-2 bg-gray-100 rounded-lg transition-all duration-300 ease-in-out hover:bg-gray-200';

    return (
      <div className={`${baseClasses} bg-gray-50`}>
        <h3 className='text-lg font-medium mb-3'>Selected Items</h3>
        <div className='space-y-2'>
          <div className={itemClasses}>
            <FolderIcon className='h-5 w-5 text-yellow-500' />
            <span>{selectedFolders.length} root folder(s) selected</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`} aria-labelledby='modal-title' role='dialog' aria-modal='true'>
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ease-in-out
          ${isOpen ? 'opacity-25' : 'opacity-0'}`}
        onClick={onClose}
      />

      <div className='fixed inset-0 overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4'>
          <div
            className={`relative w-full max-w-2xl bg-white rounded-lg shadow-xl 
              transition-all duration-300 ease-in-out transform
              ${isOpen ? 'translate-y-0 opacity-100 scale-100' : 'translate-y-4 opacity-0 scale-95'}`}>
            <div className='p-6'>
              <div className='flex justify-between items-center mb-4'>
                <div className='text-lg font-semibold transition duration-500 ease-in-out transform'>Create Knowledge Agent</div>
                <button onClick={onClose} className='text-gray-500 hover:text-gray-700 transition-colors'>
                  <XMarkIcon className='h-6 w-6' />
                </button>
              </div>

              <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                <div className='sm:flex sm:items-start'>
                  <div className='w-full'>
                    {renderSelectionSummary()}
                    <div className='mb-4'>
                      <h3 className='text-lg font-medium text-gray-900'>Basic Settings</h3>
                    </div>

                    <form onSubmit={handleSubmit} className='space-y-4'>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Agent Name</label>
                        <input
                          type='text'
                          value={config.name}
                          onChange={(e) => updateConfig('name', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          required
                        />
                      </div>

                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Welcome Message</label>
                        <textarea
                          value={config.welcomeMessage}
                          onChange={(e) => updateConfig('welcomeMessage', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          rows={2}
                        />
                      </div>

                      <div>
                        <label className='block text-sm font-medium text-gray-700'>System Message</label>
                        <textarea
                          value={config.systemPrompt}
                          onChange={(e) => updateConfig('systemPrompt', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          rows={4}
                        />
                      </div>

                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Error Message</label>
                        <textarea
                          value={config.errorMessage}
                          onChange={(e) => updateConfig('errorMessage', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          rows={2}
                        />
                      </div>

                      <div>
                        <label className='block text-sm font-medium text-gray-700'>Language</label>
                        <select
                          value={config.language}
                          onChange={(e) => updateConfig('language', e.target.value)}
                          className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                          disabled={languagesLoading}>
                          {languagesLoading ? (
                            <option>Loading languages...</option>
                          ) : (
                            languagesData?.findManyLanguages.map((language) => (
                              <option key={language.id} value={language.id}>
                                {language.name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      {error && (
                        <div className="text-red-600 text-sm mt-2">
                          {error}
                        </div>
                      )}

                      <div className='flex justify-end gap-4 mt-6'>
                        <button onClick={onClose} className='px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50'>
                          Cancel
                        </button>
                        <button 
                          type='submit'
                          disabled={isSubmitting}
                          className={`
                            px-4 py-2 rounded-md font-semibold
                            flex items-center justify-center min-w-[140px]
                            transition-all duration-200
                            ${
                              saveSuccess
                                ? 'bg-green-500 hover:bg-green-600'
                                : isSubmitting
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-uehPrimary hover:bg-uehPrimary/90'
                            }
                            text-white disabled:opacity-50
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-uehPrimary
                          `}
                        >
                          {isSubmitting ? (
                            <div className='flex items-center'>
                              <ArrowPathIcon className='animate-spin -ml-1 mr-2 h-4 w-4 text-white' />
                              Creating...
                            </div>
                          ) : saveSuccess ? (
                            <div className='flex items-center'>
                              <CheckCircleIcon className='w-5 h-5 mr-2' />
                              Created!
                            </div>
                          ) : (
                            'Create Agent'
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAgentModal;
