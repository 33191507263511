import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { IAuthUser } from '../types/IAuthUser';

interface IAuthContext {
  isSignedIn: boolean;
  user: IAuthUser | null;
  isAdmin: () => Promise<boolean>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<IAuthContext>({
  isSignedIn: false,
  user: null,
  isAdmin: () => {
    console.warn('isAdmin not implemented');
    return Promise.resolve(false);
  },
  login: () => {
    console.warn('login not implemented');
    return Promise.resolve();
  },
  logout: () => {
    console.warn('logout not implemented');
    return Promise.resolve();
  }
});

interface IKeycloakAuthProviderProps {
  children: React.ReactNode;
}

export const KeycloakAuthProvider = ({ children }: IKeycloakAuthProviderProps) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useKeycloakAuth = () => useContext(AuthContext);

declare global {
  interface Window {
    Keycloak: any;
  }
}

const useProvideAuth = (): IAuthContext => {
  const [user, setUser] = useState<IAuthUser | null>(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [keycloak, setKeycloak] = useState<any | null>(null);

  // Detect if running in web browser
  const isWebEnvironment = typeof window !== 'undefined' && window.document;

  const storeTokens = useCallback((accessToken: string, idToken: string, refreshToken?: string) => {
    localStorage.setItem('keycloak-access-token', accessToken);
    localStorage.setItem('keycloak-id-token', idToken);
    if (refreshToken) {
      localStorage.setItem('keycloak-refresh-token', refreshToken);
    }
  }, []);

  const clearTokens = useCallback(() => {
    localStorage.removeItem('keycloak-access-token');
    localStorage.removeItem('keycloak-id-token');
    localStorage.removeItem('keycloak-refresh-token');
  }, []);


  useEffect(() => {
    if (!isWebEnvironment) {
      // Non-web environment initialization
      const accessToken = process.env.KEYCLOAK_ACCESS_TOKEN;
      const idToken = process.env.KEYCLOAK_ID_TOKEN;
      if (accessToken && idToken) {
        setIsSignedIn(true);
        setUser({
          id: '', // You might want to decode the token to get the sub
          accessToken,
          idToken,
          refreshToken: ''
        });
      }
      return;
    }

    const loadKeycloakScript = async () => {
      try {
        const script = document.createElement('script');
        script.src = `/keycloak/js/keycloak.js`;
        script.async = true;

        script.onload = async () => {
          const kc = new window.Keycloak({
            url: '/keycloak',
            realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '',
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? ''
          });

          try {
            const authenticated = await kc.init({
              onLoad: 'check-sso',
              silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
              pkceMethod: 'S256',
              enableLogging: true
            });

            setKeycloak(kc);
            setIsSignedIn(authenticated);

            if (authenticated) {
              storeTokens(kc.token ?? '', kc.idToken ?? '', kc.refreshToken ?? '');
              setUser({
                id: kc.tokenParsed?.sub ?? '',
                accessToken: kc.token ?? '',
                idToken: kc.idToken ?? '',
                refreshToken: kc.refreshToken ?? ''
              });
            }
          } catch (error) {
            console.error('Failed to initialize Keycloak', error);
          }
        };

        script.onerror = () => {
          console.error('Failed to load Keycloak script');
        };

        document.body.appendChild(script);
      } catch (error) {
        console.error('Failed to load Keycloak script', error);
      }
    };

    loadKeycloakScript();
  }, [storeTokens, isWebEnvironment]);

  const isAdmin = async () => {
    if (!isSignedIn || !keycloak) {
      return false;
    }
    return keycloak.hasRealmRole('admin');
  };

  const login = async () => {
    if (!keycloak) {
      console.error('Keycloak not initialized');
      return;
    }
    try {
      await keycloak.login({
        redirectUri: window.location.origin
      });
    } catch (error) {
      console.error('Failed to initialize login', error);
    }
  };

  const logout = useCallback(async () => {
    if (!keycloak) {
      console.error('Keycloak not initialized');
      return;
    }
    try {
      clearTokens();

      await keycloak.logout({
        redirectUri: window.location.origin + '/signin'
      });
      setUser(null);
      setIsSignedIn(false);
    } catch (error) {
      console.error('Failed to logout', error);
    }
  }, [keycloak, clearTokens]);


  // Setup token refresh
  useEffect(() => {
    if (!isWebEnvironment || !keycloak || !isSignedIn) return;

    // Set up token refresh
    const refreshInterval = setInterval(async () => {
      try {
        const refreshed = await keycloak.updateToken(10); // Refresh if token has less than 10 seconds left
        if (refreshed) {
          storeTokens(keycloak.token, keycloak.idToken, keycloak.refreshToken);
          setUser({
            id: keycloak.tokenParsed?.sub ?? '',
            accessToken: keycloak.token ?? '',
            idToken: keycloak.idToken ?? '',
            refreshToken: keycloak.refreshToken ?? ''
          });
        }
      } catch (error) {
        console.error('Failed to refresh token', error);
        // Token refresh failed, user needs to login again
        await logout();
      }
    }, 10000); // Check every 10 seconds

    return () => clearInterval(refreshInterval);
  }, [keycloak, isSignedIn, isWebEnvironment, logout]);

  useEffect(() => {
    if (keycloak) {
      keycloak.onTokenExpired = () => {
        keycloak
          .updateToken(70)
          .then((refreshed: boolean) => {
            if (refreshed) {
              storeTokens(keycloak.token ?? '', keycloak.idToken ?? '', keycloak.refreshToken ?? '');

              setUser({
                id: keycloak.tokenParsed?.sub ?? '',
                accessToken: keycloak.token ?? '',
                idToken: keycloak.idToken ?? '',
                refreshToken: keycloak.refreshToken ?? ''
              });
            }
          })
          .catch(() => {
            console.error('Failed to refresh token');
            logout();
          });
      };
    }
  }, [keycloak, logout, storeTokens]);

  return {
    user,
    isSignedIn,
    isAdmin,
    login,
    logout
  };
};
