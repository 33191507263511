import { useState } from 'react';

interface IProps {
  visible: boolean;
  hideModal: () => void;
  loading: boolean;
  onOk: (name: string, description: string) => void;
}

const FolderCreateModal = ({ visible, hideModal, loading, onOk }: IProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleOk = async () => {
    try {
      if (!name.trim()) {
        setError('Folder name is required.');
        return;
      }
      await onOk(name, description);
      setName('');
      setDescription('');
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  if (!visible) return null;

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50'>
      <div className='bg-white rounded-lg shadow-lg w-3/4 max-w-md'>
        <div className='p-4 border-b'>
          <h2 className='text-lg font-semibold'>Create New Folder</h2>
        </div>
        <div className='p-4'>
          <div className="mb-4">
            <label className='block text-sm font-medium text-gray-700'>Folder Name</label>
            <input
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
              placeholder='Enter folder name'
            />
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-700'>Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
              placeholder='Enter folder description'
              rows={3}
            />
          </div>
          {error && <div className='text-red-500 mt-2'>{error}</div>}
        </div>
        <div className='flex justify-end p-4 border-t'>
          <button onClick={hideModal} className='bg-gray-500 text-white px-4 py-2 rounded mr-2'>
            Cancel
          </button>
          <button
            onClick={handleOk}
            className={`bg-uehPrimary text-white px-4 py-2 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}>
            {loading ? 'Creating...' : 'Create'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FolderCreateModal;
