import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { KeycloakAuthProvider } from './context/KeycloakAuthProvider';
import { ChatProvider } from '@/context/ChatProvider';
import { apiClient } from './apollo/client';
import './css/style.css';
import Router from './router/router';
import { ApiProvider } from './context/ApiContext';

function App() {
  return (
    <ApolloProvider client={apiClient}>
        <KeycloakAuthProvider>
            <ChatProvider>
              <ApiProvider>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </ApiProvider>
            </ChatProvider>
        </KeycloakAuthProvider>
    </ApolloProvider>
  );
}

export default App;
