import React, { useState } from 'react';
import { CloudArrowUpIcon, XMarkIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { useChat } from '@/context/ChatProvider';
import { transformFile2Base64 } from '@/utils/file_util';

interface IModalProps {
  visible: boolean;
  hideModal: () => void;
  loading: boolean;
  folderId?: string;
  onOk: (files: File[], folderId: string) => Promise<void>;
}

interface UploadFile {
  uid: string;
  name: string;
  file: File;
  content?: string;
  status?: string;
}

const ACCEPTED_FILE_TYPES = '.pdf,.md,.markdown';
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB to match server limit

const FileUpload = ({
  directory,
  fileList,
  setFileList
}: {
  directory: boolean;
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}) => {
  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    files.forEach(handleBeforeUpload);
  };

  const handleBeforeUpload = async (file: File) => {
    // Check file type
    const extension = file.name.toLowerCase().split('.').pop();
    if (!['pdf', 'md', 'markdown'].includes(extension || '')) {
      alert('Only PDF and Markdown files are accepted');
      return false;
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      alert('File size should not exceed 10MB');
      return false;
    }

    // Read file content - handle PDF and text files differently
    let content: string;
    if (extension === 'pdf') {
      content = await transformFile2Base64(file);
    } else {
      content = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.readAsText(file);
      });
    }

    setFileList((prev) => [...prev, { 
      uid: file.name, 
      name: file.name, 
      file,
      content 
    }]);
    return false;
  };

  const handleRemove = (file: UploadFile) => {
    setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
  };

  return (
    <div 
      className='border-2 border-dashed border-gray-300 rounded-lg p-8 text-center'
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      <div className='space-y-4'>
        <div className='flex justify-center'>
          <CloudArrowUpIcon className='h-12 w-12 text-gray-400' />
        </div>
        <div className='text-gray-600'>
          <p className='text-lg font-medium'>
            {directory ? 'Choose Folder' : 'Choose Files'}
          </p>
          <p className='text-sm'>
            {directory 
              ? 'Drag and drop a folder here, or click to select a folder'
              : 'Drag and drop files here, or click to select files'
            }
          </p>
          <p className='text-xs text-gray-500 mt-2'>
            Accepted file types: PDF, Markdown • Max file size: 10MB
          </p>
        </div>
        <input
          type='file'
          multiple={!directory}
          {...(directory ? { webkitdirectory: '', directory: '' } : {})}
          accept={ACCEPTED_FILE_TYPES}
          onChange={(e) => {
            const files = Array.from(e.target.files || []);
            files.forEach(handleBeforeUpload);
          }}
          className='block w-full text-sm text-gray-500 
            file:mr-4 file:py-2 file:px-4 
            file:rounded-full file:border-0 
            file:text-sm file:font-semibold
            file:bg-uehPrimary file:text-white
            hover:file:bg-uehSecondary
            cursor-pointer'
        />
      </div>

      {fileList.length > 0 && (
        <div className='mt-6 border-t pt-4'>
          <h4 className='text-sm font-medium mb-2'>Selected Files</h4>
          <ul className='space-y-2 max-h-40 overflow-y-auto'>
            {fileList.map((file) => (
              <li key={file.uid} className='flex items-center justify-between text-sm p-2 bg-gray-50 rounded'>
                <span className='truncate flex-1'>{file.name}</span>
                <button 
                  onClick={() => handleRemove(file)}
                  className='ml-2 text-red-500 hover:text-red-700'
                >
                  <XMarkIcon className='h-5 w-5' />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const UploadModal = ({ visible, hideModal, loading, folderId, onOk }: IModalProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isDirectory, setIsDirectory] = useState(false);
  const { uploadFolderTree } = useChat();

  const handleOk = async () => {
    if (fileList.length === 0) {
      alert('Please select at least one file to upload');
      return;
    }

    try {
      if (isDirectory) {
        // Upload folder
        await onOk(fileList.map(f => f.file), folderId!);
      } else {
        // Upload individual files
        await onOk(fileList.map(f => f.file), folderId!);
      }
      hideModal();
      setFileList([]);
    } catch (error) {
      console.error('Error uploading:', error);
      alert('Failed to upload files. Please try again.');
    }
  };

  if (!visible) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="relative bg-white rounded-lg p-8 max-w-lg w-full">
          <div className="absolute right-4 top-4">
            <button
              onClick={hideModal}
              className="text-gray-400 hover:text-gray-500"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          <div className="mb-4">
            <h2 className="text-xl font-semibold">Upload Documents</h2>
            <div className="mt-4 flex space-x-4">
              <button
                onClick={() => {
                  setIsDirectory(false);
                  setFileList([]);
                }}
                className={`flex-1 py-2 px-4 rounded-md ${
                  !isDirectory
                    ? 'bg-uehPrimary text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                Upload Files
              </button>
              <button
                onClick={() => {
                  setIsDirectory(true);
                  setFileList([]);
                }}
                className={`flex-1 py-2 px-4 rounded-md ${
                  isDirectory
                    ? 'bg-uehPrimary text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                Upload Folder
              </button>
            </div>
          </div>
          <div className="space-y-4">
            <FileUpload
              directory={isDirectory}
              fileList={fileList}
              setFileList={setFileList}
            />
            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={hideModal}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleOk}
                disabled={loading || fileList.length === 0}
                className={`px-4 py-2 text-sm font-medium text-white rounded-md ${
                  loading || fileList.length === 0
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-uehPrimary hover:bg-uehSecondary'
                }`}
              >
                {loading ? 'Uploading...' : 'Upload'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;
