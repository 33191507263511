import { Navigate, useRoutes } from 'react-router-dom';
import Header from '../components/Header';
import SignInPage from '../pages/SignInPage';
import UserChatPage from '../pages/user_chat/UserChatPage';
import FilePage from '../pages/file/FilePage';
import SettingsPage from '../pages/settings/SettingsPage';
import UserSettingProfile from '../pages/settings/setting_profile/SettingProfile';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isSignedIn } = useKeycloakAuth();

  if (!isSignedIn) {
    return <Navigate to='/signin' replace />;
  }

  return <>{children}</>;
};

const Router = () => {
  const routes = [
    {
      path: '/signin',
      element: <SignInPage />
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Header />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'chat',
          element: <UserChatPage />
        },
        {
          path: 'file',
          element: <FilePage />
        },
        {
          path: 'settings',
          element: <SettingsPage />,
          children: [
            {
              path: 'profile',
              element: <UserSettingProfile />
            }
          ]
        }
      ]
    }
  ];

  return useRoutes(routes);
};

export default Router;
