import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { IModalProps } from '../types/common';
import { IChunk } from '../types/knowledge';

interface IProps extends IModalProps<any> {
  documentId: string;
  chunk?: IChunk;
}

export const PdfDrawer = ({ visible = false, hideModal, documentId, chunk }: IProps) => {
  // Function to handle loading error
  const handleError = (error: Error) => {
    console.error('Error loading PDF:', error);
  };

  return (
    <div className={`fixed inset-0 z-50 transition-all duration-1000 ${visible ? 'block' : 'hidden'}`}>
      {/* Overlay with fade animation */}
      <div
        className={`fixed inset-0 bg-black/50 transition-opacity duration-1000 ease-in-out ${visible ? 'opacity-100' : 'opacity-0'}`}
        onClick={hideModal}></div>

      {/* Drawer with slide animation */}
      <div
        className={`fixed right-0 top-0 h-full w-1/2 bg-white shadow-lg overflow-y-auto
          transform transition-transform duration-1000 ease-in-out ${visible ? 'translate-x-0' : 'translate-x-full'}`}>
        {/* Header */}
        <div className='flex justify-between items-center p-4 border-b'>
          <h2 className='text-lg font-bold'>Document Previewer</h2>
          <button onClick={hideModal} className='text-gray-500 hover:text-gray-700 p-2 rounded-lg transition-colors'>
            <span className='sr-only'>Close</span>
            <svg className='w-6 h-6' fill='none' stroke='currentColor' viewBox='0 0 24 24'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className='p-4 h-[calc(100%-4rem)]'>
          <Worker workerUrl='/pdf.worker.min.js'>
            <div className='h-full'>
              <Viewer fileUrl={`src/assets/Revised_Manuscript_270324.docx`} />
            </div>
          </Worker>
        </div>
      </div>
    </div>
  );
};

export default PdfDrawer;
