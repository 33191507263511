import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';

// HTTP link for queries and mutations
const httpLink = new HttpLink({
  uri: '/graphql'
});

// Create auth middleware that uses the Keycloak token
const authMiddleware = new ApolloLink((operation, forward) => {
  // Get the current user's access token
  const token = window.localStorage.getItem('keycloak-access-token');

  // Add the auth header to the request
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }));

  return forward(operation);
});

// Combine the auth middleware with the HTTP link
const httpLinkWithAuth = authMiddleware.concat(httpLink);

export const apiClient = new ApolloClient({
  link: httpLinkWithAuth,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    }
  }
});
