import { ArrowLeftIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import { useKeycloakAuth } from '../../context/KeycloakAuthProvider';

const SettingsPage = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { isSignedIn } = useKeycloakAuth();

  useEffect(() => {
    const handleResize = () => {
      const mobileView = window.innerWidth <= 768;
      setIsMobileView(mobileView);
      if (!mobileView) {
        setIsSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (!isSignedIn) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <div className='relative flex h-screen'>
      <div
        className={`${isMobileView ? 'fixed left-0 z-20 bg-white' : 'relative'} transition-transform duration-300 ease-in-out ${
          isMobileView && !isSidebarOpen ? '-translate-x-full' : 'translate-x-0'
        }`}>
        <div className='flex items-start'>
          <SideBar />
          {isMobileView && isSidebarOpen && (
            <button onClick={toggleSidebar} className='fixed p-2 top-4 -right-12 bg-uehPrimary text-white rounded-md' aria-label='Close sidebar'>
              <ArrowLeftIcon className='h-6 w-6' />
            </button>
          )}
        </div>
      </div>
      <div className='flex-1 overflow-hidden'>
        {isMobileView && !isSidebarOpen && (
          <div className='fixed left-0 z-10 mt-4 ml-4'>
            <button onClick={toggleSidebar} className='p-2 bg-uehPrimary text-white rounded-md' aria-label='Toggle sidebar'>
              <Bars3Icon className='h-6 w-6' />
            </button>
          </div>
        )}
        <div className='h-full overflow-auto pt-12'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
