import React, { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MessageInput from './MessageInput';
import MessageItem from './MessageItem';
import PdfDrawer from '../PdfDrawer';
import { Message } from '@/gql/graphql';

interface ChatContainerProps {
  messages: Message[];
  loading?: boolean;
  onSendMessage: (message: string) => void;
  isLoading?: boolean;
  isWaitingForResponse?: boolean;
  streamingMessage?: Message | null;
  temporalUserMessage?: Message | null;
  messageRefetchNotified?: boolean;
}

const ChatContainer: React.FC<ChatContainerProps> = ({
  messages,
  loading = false,
  onSendMessage,
  isLoading = false,
  isWaitingForResponse = false,
  streamingMessage,
  temporalUserMessage
}) => {
  const [inputValue, setInputValue] = useState('');
  const [documentId, setDocumentId] = useState<string>('');
  const [selectedChunk, setSelectedChunk] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const isNearBottom = useCallback(() => {
    const container = chatContainerRef.current;
    if (!container) return true;

    const threshold = 100; // pixels from bottom
    const position = container.scrollHeight - container.scrollTop - container.clientHeight;
    return position < threshold;
  }, []);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      setShouldAutoScroll(isNearBottom());
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [isNearBottom]);

  const displayMessages = useMemo(() => {
    let result = [...messages];

    // Add temporal user message if it exists and is not already in the messages array
    if (temporalUserMessage && !messages.some((m) => m.id === temporalUserMessage.id)) {
      result = [...result, temporalUserMessage];
    }

    // Add streaming message if it exists and is not already in the messages array
    if (streamingMessage && streamingMessage.text && !messages.some((m) => m.id === streamingMessage.id)) {
      result = [...result, streamingMessage];
    }

    return result;
  }, [messages, temporalUserMessage, streamingMessage]);

  // Auto-scroll logic
  useEffect(() => {
    if (shouldAutoScroll && messagesEndRef.current && chatContainerRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [shouldAutoScroll, displayMessages, streamingMessage?.text]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = useCallback(() => {
    console.log('Send attempt - Current states:', {
      inputValue: inputValue.trim(),
      isWaitingForResponse,
      isLoading,
      canSend: !(!inputValue.trim() || isWaitingForResponse)
    });

    if (!inputValue.trim() || isWaitingForResponse) return;

    // Send message through Socket.IO
    onSendMessage(inputValue.trim());
    setInputValue('');
  }, [inputValue, isWaitingForResponse, onSendMessage, isLoading]);

  const rateMessage = useCallback((rating: number) => {
    console.log('Rating message:', rating);
  }, []);

  const clickDocumentButton = useCallback((docId: string, chunk: any) => {
    setDocumentId(docId);
    setSelectedChunk(chunk);
    setVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <div className='flex h-[calc(100vh-8rem)] flex-col md:h-[calc(100vh-5rem)]'>
      <div ref={chatContainerRef} className='scrollbar-hide flex-1 overflow-y-auto p-4'>
        {loading ? (
          <div className='flex h-full items-center justify-center'>
            <div className='text-center text-gray-500'>Loading messages...</div>
          </div>
        ) : displayMessages.length === 0 && !streamingMessage ? (
          <div className='flex h-full flex-col items-center justify-center space-y-4'>
            <div className='rounded-full bg-gray-100 p-4'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-8 w-8 text-gray-400' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z'
                />
              </svg>
            </div>
            <div className='text-center'>
              <h3 className='mb-1 text-lg font-semibold text-gray-900'>Start a conversation</h3>
              <p className='text-gray-500'>Send a message to begin chatting with the assistant</p>
            </div>
          </div>
        ) : (
          <Fragment>
            {displayMessages.map((message: Message, index: number) => (
              <MessageItem
                key={message.id}
                item={message}
                clickDocumentButton={clickDocumentButton}
                index={index}
                rateMessage={rateMessage}
                showLikeButton={true}
              />
            ))}
          </Fragment>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className='border-t p-4'>
        <MessageInput
          disabled={loading}
          sendDisabled={!inputValue.trim() || isWaitingForResponse}
          sendLoading={isWaitingForResponse}
          value={inputValue}
          onInputChange={handleInputChange}
          onSend={handleSendMessage}
        />
      </div>
      {visible && <PdfDrawer visible={visible} hideModal={hideModal} documentId={documentId} chunk={selectedChunk} />}
    </div>
  );
};

export default memo(ChatContainer);
