import { ChatBubbleBottomCenterTextIcon, ClipboardDocumentIcon, HandThumbUpIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as QuoteIcon } from '../../assets/svg/quote.svg';
import MarkdownContent from './MarkdownContent';
import { Message, MessageRole } from '@/gql/graphql';

interface IProps {
  item: Message;
  loading?: boolean;
  avatar?: string;
  sendLoading?: boolean;
  nickname?: string;
  clickDocumentButton?: (id: string, chunk: any) => void;
  index?: number;
  rateMessage?: (rating: number) => void;
  editUserMessage?: (content: string) => void;
  showLikeButton?: boolean;
}

const MessageItem = ({
  item,
  loading = false,
  avatar = '',
  sendLoading = false,
  nickname,
  clickDocumentButton,
  index,
  rateMessage,
  editUserMessage,
  showLikeButton = true
}: IProps) => {
  const [copied, setCopied] = useState(false);
  const [rating, setRating] = useState<number | null>(null);
  const [showRatingStars, setShowRatingStars] = useState(false);
  const [hover, setHover] = useState<number>(0);
  const [isReferenceOpen, setIsReferenceOpen] = useState(false);
  const [isEditUserMessage, setIsEditUserMessage] = useState(false);
  const [editedContent, setEditedContent] = useState(item.text ?? '');
  const isAssistant = useMemo(() => item.role === MessageRole.Assistant, [item.role]);
  const isUser = useMemo(() => item.role === MessageRole.User, [item.role]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleCopy = async () => {
    try {
      if (!navigator.clipboard) {
        // Fallback for older browsers
        const textArea = document.createElement('textarea');
        textArea.value = item.text ?? '';
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      } else {
        // Modern browsers
        await navigator.clipboard.writeText(item.text ?? '');
      }
      // Optional: Show success message
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleRate = useCallback(() => {
    setShowRatingStars(true);
  }, []);

  const handleRatingSubmit = useCallback(
    (value: number) => {
      setRating(value);
      setShowRatingStars(false);
      rateMessage?.(value);
    },
    [rateMessage]
  );

  const handleReferenceClick = useCallback(() => {
    setIsReferenceOpen(!isReferenceOpen);
  }, [isReferenceOpen]);

  const handleEditUserMessage = useCallback(() => {
    setIsEditUserMessage(true);
    setEditedContent(item.text ?? '');
  }, [item.text]);

  const handleSaveEdit = useCallback(() => {
    editUserMessage?.(editedContent);
    setIsEditUserMessage(false);
  }, [editUserMessage, editedContent]);

  const handleCancelEdit = useCallback(() => {
    setIsEditUserMessage(false);
    setEditedContent(item.text ?? '');
  }, [item.text]);

  const handleTextareaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedContent(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }, []);

  useEffect(() => {
    // If the textarea is not focused, scroll to the bottom
    // This is to prevent the textarea from being scrolled to the bottom when the user is typing
    // If the textarea is focused, the scroll position will be lower than the height of the textarea so user can see the button below the textarea
    if (textareaRef.current && !textareaRef.current.contains(document.activeElement)) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight - textareaRef.current.clientHeight;
    }
  }, [editedContent]);

  const renderAvatar = () => {
    if (isUser) {
      return (
        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-uehPrimary text-white font-medium'>
          {item.user?.firstName?.[0] || 'U'}
        </div>
      );
    }
    // Return AI avatar for assistant messages with the same color scheme
    return (
      <div className='flex h-8 w-8 items-center justify-center rounded-full bg-uehPrimary text-white'>
        <span className='text-sm font-medium'>AI</span>
      </div>
    );
  };

  return (
    <div
      className={classNames('mb-6 flex', {
        'justify-start': isAssistant,
        'justify-end': isUser
      })}>
      <div
        className={classNames('rounded-lg p-4', {
          'bg-gray-100': isAssistant,
          'bg-emerald-100': isUser
        })}>
        <div className='mb-3 flex items-center gap-3'>
          {renderAvatar()}
          <span className='font-semibold'>{isUser ? item.user?.firstName || 'User' : 'Assistant'}</span>
        </div>

        <div className='prose'>
          {isEditUserMessage ? (
            <div className='flex w-full flex-col gap-2'>
              <textarea
                ref={textareaRef}
                value={editedContent}
                onChange={handleTextareaChange}
                className='max-h-[9rem] min-h-[3rem] w-full resize-none overflow-y-auto rounded border focus:border-uehPrimary focus:outline-none focus:ring-1 focus:ring-uehPrimary'
                style={{
                  height: 'auto',
                  width: '100%',
                  boxSizing: 'border-box'
                }}
              />
            </div>
          ) : (
            <MarkdownContent content={item.text ?? ''} loading={loading} clickDocumentButton={clickDocumentButton} />
          )}
        </div>

        <div className='mt-3 flex flex-col'>
          <div className='flex justify-end gap-2'>
            <button
              onClick={handleCopy}
              className={classNames('rounded p-1 hover:bg-gray-200', {
                'text-uehPrimary': copied,
                'text-gray-500': !copied
              })}
              title={copied ? 'Đã copy!' : 'Sao chép vào clipboard'}>
              <ClipboardDocumentIcon className='size-5' />
            </button>
            {isUser && !isEditUserMessage && (
              <button onClick={handleEditUserMessage} className='rounded p-1 text-gray-500 hover:bg-gray-200' title='Sửa tin nhắn'>
                <PencilSquareIcon className='size-5' />
              </button>
            )}

            {isAssistant && showLikeButton && index !== undefined && (
              <>
                <button onClick={handleReferenceClick} className='rounded p-1 text-gray-500 hover:bg-gray-200' title='Xem nguồn'>
                  <QuoteIcon className='size-5' />
                </button>
                {showRatingStars ? (
                  <div className='flex items-center gap-1'>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <button
                        key={star}
                        onClick={() => handleRatingSubmit(star)}
                        onMouseEnter={() => setHover(star)}
                        onMouseLeave={() => setHover(0)}
                        className='transition-transform hover:scale-110 focus:outline-none'>
                        <StarIconSolid className={`h-5 w-5 ${star <= (hover || rating || 0) ? 'text-yellow-400' : 'text-gray-400'}`} />
                      </button>
                    ))}
                  </div>
                ) : (
                  <button
                    onClick={handleRate}
                    disabled={sendLoading}
                    className='flex items-center gap-1 rounded text-gray-500 hover:bg-gray-200'
                    title='Đánh giá'>
                    {rating ? (
                      <>
                        <span className='text-sm font-medium'>{rating}</span>
                        <StarIconSolid className='size-5 text-yellow-400' />
                      </>
                    ) : (
                      <HandThumbUpIcon className={classNames('size-5', { 'animate-pulse': sendLoading })} />
                    )}
                  </button>
                )}
              </>
            )}
          </div>
          {isReferenceOpen && (
            <div className='mt-2'>
              <a href={`#${item.id}`} className='italic text-gray-500 hover:text-uehPrimary hover:underline'>
                Không có tài liệu liên quan
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(MessageItem);
