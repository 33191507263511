import React from 'react';
import { 
  CheckCircleIcon, 
  ExclamationCircleIcon,
  ClockIcon,
  ArrowPathIcon,
  DocumentIcon,
  CloudArrowUpIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  DocumentChartBarIcon,
  DocumentPlusIcon,
  DocumentArrowUpIcon
} from '@heroicons/react/24/outline';
import { DocumentStatus } from '@/gql/graphql';

interface StatusIndicatorProps {
  status: DocumentStatus;
  message?: string;
  progress?: number;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, message, progress }) => {
  const getStatusConfig = () => {
    if (progress !== undefined && status === DocumentStatus.Processing) {
      return {
        icon: <CloudArrowUpIcon className="w-6 h-6 text-blue-500" />,
        text: `Uploading ${progress}%`,
        bgColor: 'bg-blue-100',
        textColor: 'text-blue-700'
      };
    }

    switch (status) {
      case DocumentStatus.Completed:
        return {
          icon: <CheckCircleIcon className="w-6 h-6 text-emerald-500" />,
          text: 'Completed',
          bgColor: 'bg-emerald-100',
          textColor: 'text-emerald-700'
        };
      case DocumentStatus.Error:
        return {
          icon: <ExclamationCircleIcon className="w-5 h-5 text-rose-500" />,
          text: 'Error',
          bgColor: 'bg-rose-100',
          textColor: 'text-rose-700'
        };
      case DocumentStatus.Processing:
        return {
          icon: <ArrowPathIcon className="w-6 h-6 text-blue-500 animate-spin" />,
          text: 'Processing',
          bgColor: 'bg-blue-100',
          textColor: 'text-blue-700'
        };
      default:
        return {
          icon: <DocumentIcon className="w-5 h-5 text-gray-500" />,
          text: 'Pending',
          bgColor: 'bg-gray-100',
          textColor: 'text-gray-700'
        };
    }
  };

  const config = getStatusConfig();

  return (
    <div className="flex items-center space-x-2">
      {config.icon}
      <div className={`px-2 py-1 rounded-full ${config.bgColor}`}>
        <span className={`text-sm font-medium ${config.textColor}`}>
          {config.text}
        </span>
      </div>
      {message && (
        <span className="text-sm text-gray-500">{message}</span>
      )}
    </div>
  );
};

export default StatusIndicator;