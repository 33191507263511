import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import uehLogo from '../assets/images/logo/logo-ueh/logo-ueh-xanh.png';
import { useKeycloakAuth } from '@/context/KeycloakAuthProvider';

export const SigninPage = () => {
  const { login, isSignedIn } = useKeycloakAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignedIn) {
      navigate('/chat');
    }
  }, [isSignedIn, navigate]);

  return (
    <main className='grow bg-white dark:bg-slate-900'>
      <div className='flex min-h-screen items-center justify-center bg-gray-50'>
        <div className='w-full max-w-md space-y-8'>
          <div className='flex flex-col items-center'>
            <img src={uehLogo} alt='UEH Logo' className='h-16 w-24' />
            <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>Welcome to UEH Chatbot</h2>
            <p className='mt-2 text-center text-sm text-gray-600'>Please sign in to continue</p>
          </div>
          <div className='mt-8 space-y-6'>
            <button
              onClick={login}
              className='folder relative flex w-full justify-center rounded-md border border-transparent bg-uehPrimary px-4 py-2 text-sm font-medium text-white hover:bg-uehSecondary focus:outline-none focus:ring-2 focus:ring-uehPrimary focus:ring-offset-2'>
              Sign in with UEH Account
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SigninPage;
