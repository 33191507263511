import { PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import React, { useCallback, useRef, useState } from 'react';

interface IProps {
  disabled: boolean;
  sendDisabled: boolean;
  sendLoading: boolean;
  value: string;
  onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: () => void;
}

const MessageInput: React.FC<IProps> = ({
  disabled,
  sendDisabled,
  sendLoading,
  value,
  onInputChange,
  onSend
}) => {
  const [rows, setRows] = useState(1);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        if (!sendDisabled) {
          onSend();
        }
      }
    },
    [sendDisabled, onSend]
  );

  return (
    <div className='relative'>
      <textarea
        ref={textAreaRef}
        className={`w-full resize-none rounded-lg border px-3 py-2 text-gray-700 focus:border-uehPrimary focus:outline-none ${
          disabled ? 'bg-gray-100' : 'bg-white'
        }`}
        rows={rows}
        placeholder='Type a message...'
        value={value}
        onChange={onInputChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      <div className='absolute bottom-3 right-2 flex items-center'>
        <button
          className={`flex h-8 w-8 items-center justify-center rounded-full p-2 ${
            sendDisabled || sendLoading
              ? 'cursor-not-allowed bg-gray-300'
              : 'bg-uehPrimary hover:bg-uehSecondary hover:transition-colors hover:duration-300'
          }`}
          onClick={onSend}
          disabled={sendDisabled || sendLoading}>
          <SendOutlined className={`text-lg ${sendDisabled || sendLoading ? 'text-gray-500' : 'text-white'}`} />
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
