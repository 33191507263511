import { createContext, ReactNode, useCallback, useContext } from 'react';
import { useKeycloakAuth } from './KeycloakAuthProvider';
import {
  ChunkPayload,
  ChunksPayload,
  ConnectPayload,
  DatacountResponse,
  DocumentFilter,
  FolderNamesResponse,
  FoldersResponse,
  HealthPayload,
  MetadataPayload,
  RAGConfig,
  RAGConfigResponse,
  Theme,
  ThemeConfigResponse,
  Themes,
  UserConfig,
  UserConfigResponse
} from '@/types/types';
import { Agent, Folder } from '@/gql/graphql';

interface ApiContextType {
  fetchWithAuth: <T>(endpoint: string, options?: RequestInit) => Promise<T | null>;
  connectToVerba: () => Promise<ConnectPayload | null>;
  fetchHealth: () => Promise<HealthPayload | null>;
  fetchRAGConfig: () => Promise<RAGConfigResponse | null>;
  updateRAGConfig: (RAG: RAGConfig | null) => Promise<boolean>;
  fetchUserConfig: () => Promise<UserConfigResponse | null>;
  updateUserConfig: (user_config: UserConfig) => Promise<boolean>;
  fetchThemeConfig: () => Promise<ThemeConfigResponse | null>;
  updateThemeConfig: (themes: Themes, theme: Theme) => Promise<boolean>;
  fetchDatacount: (documentFilter: DocumentFilter[]) => Promise<DatacountResponse | null>;
  fetchFolderNames: () => Promise<FolderNamesResponse | null>;
  fetchFolders: () => Promise<FoldersResponse | null>;
  addFolder: (folder: Folder) => Promise<{ status: number; folder?: Folder; status_msg?: string }>;
  createFolder: (name: string, description: string, parentId?: string) => Promise<{ status: number; folder?: Folder; status_msg?: string }>;
  updateFolder: (folder: Folder) => Promise<{ status: number; folder?: Folder; status_msg?: string }>;
  deleteFolder: (folderId: string) => Promise<{ status: number; folder?: Folder; status_msg?: string }>;
  fetch_chunks: (uuid: string | null, page: number, pageSize: number) => Promise<ChunksPayload | null>;
  fetch_chunk: (uuid: string | null) => Promise<ChunkPayload | null>;
  deleteDocument: (uuid: string) => Promise<boolean>;
  deleteAllDocuments: (resetMode: string) => Promise<boolean>;
  fetchMeta: () => Promise<MetadataPayload | null>;
  updateMessage: (messageId: string, text: string) => Promise<any>;
  deleteChatRoom: (chatRoomId: string) => Promise<any>;
  updateChatRoom: (chatRoomId: string, name: string) => Promise<any>;
  createAgent: (
    name: string,
    welcomeMessage: string,
    systemPrompt: string,
    errorMessage: string,
    language: string,
    folders: string[]
  ) => Promise<Agent | null>;
  updateAgent: (agentId: string, agent: Agent) => Promise<{ status: number; agent?: Agent; status_msg?: string }>;
  deleteAgent: (agentId: string) => Promise<{ status: number; agent?: Agent; status_msg?: string }>;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

export function ApiProvider({ children }: { children: ReactNode }) {
  const { user } = useKeycloakAuth();

  const checkUrl = useCallback(async (url: string): Promise<boolean> => {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      console.error(`Failed to fetch from ${url}:`, error);
      return false;
    }
  }, []);

  const fetchWithAuth = useCallback(
    async <T,>(endpoint: string, options: RequestInit = {}): Promise<T | null> => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          ...(user?.accessToken && { Authorization: `Bearer ${user.accessToken}` }),
          ...options.headers
        };

        const response = await fetch(`${endpoint}`, {
          ...options,
          headers
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
      } catch (error) {
        console.error(`Failed to fetch from ${endpoint}:`, error);
        return null;
      }
    },
    [user?.accessToken]
  );

  // API Functions
  const fetchHealth = useCallback(async (): Promise<HealthPayload | null> => {
    return fetchWithAuth('/api/health');
  }, [fetchWithAuth]);

  const fetchRAGConfig = useCallback(async (): Promise<RAGConfigResponse | null> => {
    return fetchWithAuth('/api/get_rag_config', {
      method: 'POST'
    });
  }, [fetchWithAuth]);

  const updateRAGConfig = useCallback(
    async (RAG: RAGConfig | null): Promise<boolean> => {
      if (!RAG) return false;

      const response = await fetchWithAuth('/api/set_rag_config', {
        method: 'POST',
        body: JSON.stringify({ rag_config: RAG })
      });

      return !!response;
    },
    [fetchWithAuth]
  );

  const fetchUserConfig = useCallback(async (): Promise<UserConfigResponse | null> => {
    return fetchWithAuth('/api/get_user_config', {
      method: 'POST'
    });
  }, [fetchWithAuth]);

  const updateUserConfig = useCallback(
    async (user_config: UserConfig): Promise<boolean> => {
      const response = await fetchWithAuth('/api/set_user_config', {
        method: 'POST',
        body: JSON.stringify({ user_config })
      });

      return !!response;
    },
    [fetchWithAuth]
  );

  const fetchThemeConfig = useCallback(async (): Promise<ThemeConfigResponse | null> => {
    return fetchWithAuth('/api/get_theme_config', {
      method: 'POST'
    });
  }, [fetchWithAuth]);

  const updateThemeConfig = useCallback(
    async (themes: Themes, theme: Theme): Promise<boolean> => {
      const response = await fetchWithAuth('/api/set_theme_config', {
        method: 'POST',
        body: JSON.stringify({ themes, theme })
      });

      return response ? true : false;
    },
    [fetchWithAuth]
  );

  const fetchDatacount = useCallback(
    async (documentFilter: DocumentFilter[]): Promise<DatacountResponse | null> => {
      return fetchWithAuth('/api/get_datacount', {
        method: 'POST',
        body: JSON.stringify({ documentFilter })
      });
    },
    [fetchWithAuth]
  );

  const fetchFolderNames = useCallback(async (): Promise<FolderNamesResponse | null> => {
    return fetchWithAuth('/api/get_folder_names', {
      method: 'POST'
    });
  }, [fetchWithAuth]);

  const fetchFolders = useCallback(async (): Promise<FoldersResponse | null> => {
    return fetchWithAuth('/api/get_folders', {
      method: 'POST'
    });
  }, [fetchWithAuth]);

  const addFolder = useCallback(
    async (folder: Folder): Promise<{ status: number; folder?: Folder; status_msg?: string }> => {
      const result: any = await fetchWithAuth('/api/add_folder', {
        method: 'POST',
        body: JSON.stringify({ folder })
      });

      if (!result || result.status !== 200) {
        throw new Error(result?.status_msg || 'Failed to add folder');
      }

      return result;
    },
    [fetchWithAuth]
  );

  const createFolder = useCallback(
    async (name: string, description: string, parentId?: string): Promise<{ status: number; folder?: Folder; status_msg?: string }> => {
      try {
        const response = await fetchWithAuth<{ folder: Folder; message: string }>('/api/create_folder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name,
            description,
            parentId
          })
        });

        if (!response) {
          return {
            status: 500,
            status_msg: 'Failed to create folder - no response'
          };
        }

        return {
          status: 200,
          folder: response.folder,
          status_msg: response.message
        };
      } catch (error) {
        console.error('Error creating folder:', error);
        return {
          status: 500,
          status_msg: error instanceof Error ? error.message : 'Unknown error occurred'
        };
      }
    },
    [fetchWithAuth]
  );

  const updateFolder = useCallback(
    async (folder: Folder): Promise<{ status: number; folder?: Folder; status_msg?: string }> => {
      const result: any = await fetchWithAuth('/api/update_folder', {
        method: 'POST',
        body: JSON.stringify({ folder })
      });

      if (!result || result.status !== 200) {
        throw new Error(result?.status_msg || 'Failed to update folder');
      }

      return result;
    },
    [fetchWithAuth]
  );

  const deleteFolder = useCallback(
    async (folderId: string): Promise<{ status: number; folder?: Folder; status_msg?: string }> => {
      const result: any = await fetchWithAuth('/api/delete_folder', {
        method: 'POST',
        body: JSON.stringify({ folder_id: folderId })
      });

      if (!result || result.status !== 200) {
        throw new Error(result?.status_msg || 'Failed to delete folder');
      }

      return result;
    },
    [fetchWithAuth]
  );

  const fetch_chunks = useCallback(
    async (uuid: string | null, page: number, pageSize: number): Promise<ChunksPayload | null> => {
      if (!uuid) return null;

      return fetchWithAuth('/api/get_chunks', {
        method: 'POST',
        body: JSON.stringify({ uuid, page, pageSize })
      });
    },
    [fetchWithAuth]
  );

  const fetch_chunk = useCallback(
    async (uuid: string | null): Promise<ChunkPayload | null> => {
      if (!uuid) return null;

      return fetchWithAuth('/api/get_chunk', {
        method: 'POST',
        body: JSON.stringify({ uuid })
      });
    },
    [fetchWithAuth]
  );

  const deleteDocument = useCallback(
    async (uuid: string): Promise<boolean> => {
      const response = await fetchWithAuth('/api/delete_document', {
        method: 'POST',
        body: JSON.stringify({ uuid })
      });

      return !!response;
    },
    [fetchWithAuth]
  );

  const deleteAllDocuments = useCallback(
    async (resetMode: string): Promise<boolean> => {
      const response = await fetchWithAuth('/api/reset', {
        method: 'POST',
        body: JSON.stringify({ resetMode })
      });

      return response ? true : false;
    },
    [fetchWithAuth]
  );

  const fetchMeta = useCallback(async (): Promise<MetadataPayload | null> => {
    return fetchWithAuth('/api/get_meta', {
      method: 'POST'
    });
  }, [fetchWithAuth]);

  const connectToVerba = useCallback(async (): Promise<ConnectPayload | null> => {
    return fetchWithAuth('/api/connect', {
      method: 'POST'
    });
  }, [fetchWithAuth]);

  const updateMessage = useCallback(
    async (messageId: string, text: string) => {
      return fetchWithAuth('/api/message', {
        method: 'PUT',
        body: JSON.stringify({
          message_id: messageId,
          text: text
        })
      });
    },
    [fetchWithAuth]
  );

  const deleteChatRoom = useCallback(
    async (chatRoomId: string) => {
      return fetchWithAuth('/api/chat-room', {
        method: 'DELETE',
        body: JSON.stringify({
          chat_room_id: chatRoomId
        })
      });
    },
    [fetchWithAuth]
  );

  const updateChatRoom = useCallback(
    async (chatRoomId: string, name: string) => {
      return fetchWithAuth('/api/chat-room', {
        method: 'PUT',
        body: JSON.stringify({
          chat_room_id: chatRoomId,
          name: name
        })
      });
    },
    [fetchWithAuth]
  );

  const createAgent = useCallback(
    async (
      name: string,
      welcomeMessage: string,
      systemPrompt: string,
      errorMessage: string,
      language: string,
      folderIds: string[]
    ): Promise<Agent | null> => {
      const agentPayload = {
        agent: {
          name,
          systemMessage: systemPrompt,
          welcomeMessage,
          errorMessage,
          languageId: language,
          agentFolders: folderIds.map((folderId) => ({
            folderId
          }))
        }
      };

      try {
        const response = await fetchWithAuth<{ agent: Agent }>('/api/create_agent', {
          method: 'POST',
          body: JSON.stringify(agentPayload)
        });

        return response?.agent || null;
      } catch (error) {
        console.error('Error creating agent:', error);
        return null;
      }
    },
    [fetchWithAuth]
  );

  const updateAgent = useCallback(
    async (agentId: string, agent: Agent): Promise<{ status: number; agent?: Agent; status_msg?: string }> => {
      try {
        const updatePayload = {
          agent: {
            id: agentId,
            name: agent.name,
            systemMessage: agent.systemMessage,
            welcomeMessage: agent.welcomeMessage,
            errorMessage: agent.errorMessage,
            languageId: agent.languageId,
            agentFolders: agent.agentFolders?.map((af) => ({
              folderId: af.folderId || af.folder?.id
            }))
          }
        };

        const response = await fetchWithAuth<{ status: number; agent?: Agent; status_msg?: string }>(`/api/update_agent/${agentId}`, {
          method: 'POST',
          body: JSON.stringify(updatePayload)
        });

        if (!response || response.status !== 200) {
          throw new Error(response?.status_msg || 'Failed to update agent');
        }

        return response;
      } catch (error) {
        console.error('Error updating agent:', error);
        return {
          status: 500,
          status_msg: error instanceof Error ? error.message : 'Unknown error occurred'
        };
      }
    },
    [fetchWithAuth]
  );

  const deleteAgent = useCallback(
    async (agentId: string): Promise<{ status: number; agent?: Agent; status_msg?: string }> => {
      try {
        const response = await fetchWithAuth<{ status: number; agent?: Agent; status_msg?: string }>(`/api/delete_agent/${agentId}`, {
          method: 'POST'
        });

        if (!response || response.status !== 200) {
          throw new Error(response?.status_msg || 'Failed to delete agent');
        }

        return response;
      } catch (error) {
        console.error('Error deleting agent:', error);
        return {
          status: 500,
          status_msg: error instanceof Error ? error.message : 'Unknown error occurred'
        };
      }
    },
    [fetchWithAuth]
  );

  return (
    <ApiContext.Provider
      value={{
        fetchWithAuth,
        connectToVerba,
        fetchHealth,
        fetchRAGConfig,
        updateRAGConfig,
        fetchUserConfig,
        updateUserConfig,
        fetchThemeConfig,
        updateThemeConfig,
        fetchDatacount,
        fetchFolderNames,
        fetchFolders,
        addFolder,
        createFolder,
        updateFolder,
        deleteFolder,
        fetch_chunks,
        fetch_chunk,
        deleteDocument,
        deleteAllDocuments,
        fetchMeta,
        updateMessage,
        deleteChatRoom,
        updateChatRoom,
        createAgent,
        updateAgent,
        deleteAgent
      }}>
      {children}
    </ApiContext.Provider>
  );
}

export function useApi() {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
}
